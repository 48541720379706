<template>
    <div class="mt-2 wi100 hi100" v-loading="loading">
        <div class="quality-container">
            <el-page-header @back="goBack">
                <span slot="content" class="fwb gb-default-color">企业信用在线评估</span>
            </el-page-header>
            <div class="mt-4 ml-4 wi100 header-content">
                <div class="wi60">
                    <div class="uf">
                        <div class="mt-1" style="margin: auto 0">
                            <el-avatar shape="circle" size="large" :src="enterpriseInfo.imageUrl" type="round"></el-avatar>
                        </div>
                        <div class="ml-1">
                            <div class="pl-2 mr-2 f20"><span>{{ enterpriseInfo.name }}</span> <el-tag size="mini">{{enterpriseInfo.status}}</el-tag></div>
                            <div class="f14 pl-2 mt-2" style="color: #909399">
                                <span>{{enterpriseInfo.englishName}}</span>
                                <el-divider direction="vertical"></el-divider>
                                <span>{{enterpriseInfo.taxpayerType}}</span>
                                <el-divider direction="vertical"></el-divider>
                                <span>官网：{{enterpriseInfo.websiteUrl}}</span>
                                <el-divider direction="vertical"></el-divider>
                                <span>成立日期：{{enterpriseInfo.startDate | dateFilter}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header-extra tac wi40" v-if="old">
                    <div style="margin-right: 60px">
                        <div class="fwb f18 mt-2" style="min-width: 180px">信用得分：
                            <span style="font-size: 24px;color: #df0e03">{{creditInfo.finalscore}}</span></div>
                    </div>
                    <div class="custom-divider"></div>
                    <div style="margin-right: 80px">
                        <div class="fwb f18 mt-2" style="min-width: 180px">信用评级：
                            <span style="font-size: 24px;color: #05a4ff">{{creditInfo.finalclass}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-center m-4" style="margin-right: 1.5rem" v-if="old">
            <div class="wi80">
                <el-card class="mt-3" shadow="never">
                    <el-descriptions title="财务风险" :labelStyle="labelStyle" :contentStyle="contentStyle" :columns="3" size="small" border>
                        <el-descriptions-item :span="3">
                            <span slot="label">财务风险</span>
                            {{creditInfo.class1info1}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">长短期偿债能力</span>
                            {{creditInfo.class2info1}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">盈利能力</span>
                            {{creditInfo.class2info2}}
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions title="履约风险" :labelStyle="labelStyle" :contentStyle="contentStyle" :columns="3" size="small" border style="margin-top: 20px">
                        <el-descriptions-item :span="3">
                            <span slot="label">履约风险</span>
                            {{creditInfo.class1info2}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">合同履约</span>
                            {{creditInfo.class2info3}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">融资信用</span>
                            {{creditInfo.class2info4}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">福利与社保</span>
                            {{creditInfo.class2info5}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">质量承诺履约</span>
                            {{creditInfo.class2info6}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">工资及支付</span>
                            {{creditInfo.class2info7}}
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions title="运营风险" :labelStyle="labelStyle" :contentStyle="contentStyle" :columns="3" size="small" border style="margin-top: 20px">
                        <el-descriptions-item :span="3">
                            <span slot="label">运营风险</span>
                            {{creditInfo.class1info3}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">营运能力</span>
                            {{creditInfo.class2info8}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">发展能力</span>
                            {{creditInfo.class2info9}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">管理能力</span>
                            {{creditInfo.class2info10}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <span slot="label">创新能力</span>
                            {{creditInfo.class2info11}}
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-row>
                        <el-col :span="12">
                            <div id="secondChart" style="width: 100%;height: 400px;margin-top: 50px"></div>
                        </el-col>
                        <el-col :span="12">
                            <div id="firstChart" style="width: 100%;height: 400px;margin-top: 50px"></div>
                        </el-col>
                    </el-row>
                    <el-descriptions :labelStyle="labelStyle" :columns="3" size="small" border style="margin-top: 20px">
                        <el-descriptions-item :span="3">
                            <span slot="label" style="font-size: 20px;font-weight: bold;color: black">评价结果说明</span>
                            <span style="font-size: 16px;" v-if="creditInfo.finalclass==='AAA+' || creditInfo.finalclass==='AAA' || creditInfo.finalclass==='AAA-'">
                                信用评级为AAA级的企业，信用极好。该等级企业的财务报表往往呈现特征为：营业收入高、资金实力充足、资产质量良好，不存在不良的信用记录等，同时信用评级AAA的企业具有强大的履约能力，该等级企业在一定的财务困境中，也往往能够顺利度过难关。
                                AAA+等级表示略高于AAA级，AAA-表示略低于AAA级，(+)/(-)不影响企业所属信用等级的评价标准。
                            </span>
                            <span style="font-size: 16px;" v-if="creditInfo.finalclass==='AA+' || creditInfo.finalclass==='AA' || creditInfo.finalclass==='AA-'">
                               企业信用程度优良。该等级企业的财务报表往往呈现特征为：经营情况良好，盈利能力强，企业以往的信用记录良好等，同时信用评级为AA的企业具有良好的履约能力，在财务困境中有一定抗风险能力。
                                AA+等级表示略高于AA级，AA-表示略低于AA级，(+)/(-)不影响企业所属信用等级的评价标准。
                            </span>
                            <span style="font-size: 16px;" v-if="creditInfo.finalclass==='A+' || creditInfo.finalclass==='A' || creditInfo.finalclass==='A-'">
                                企业的信用程度良好，该等级企业在正常情况下偿还债务没有问题。该类企业具有良好的信用记录，经营处于良性循环状态，
                                但是可能存在一些影响其未来经营与发展的不确定因素，进而削弱其盈利能力和偿还能力。
                                A+等级表示略高于A级，A-表示略低于A级，(+)/(-)不影响企业所属信用等级的评价标准。
                            </span>
                            <span style="font-size: 16px;" v-if="creditInfo.finalclass==='BBB+' || creditInfo.finalclass==='BBB' || creditInfo.finalclass==='BBB-'">
                                企业的信用程度一般，该等级企业的信用记录正常，但其经营状况、盈利水平及未来发展易受不确定因素的影响，偿债能力有波动。
                                BBB+等级表示略高于BBB级，BBB-表示略低于BBB级，(+)/(-)不影响企业所属信用等级的评价标准。
                            </span>
                            <span style="font-size: 16px;" v-if="creditInfo.finalclass==='BB+' || creditInfo.finalclass==='BB' || creditInfo.finalclass==='BB-'">
                               信用级别较差。该等级企业主要的特点是偿还能力不足、信用记录一般、企业经营不稳定，如果金融机构给予这类企业贷款，有可能出现无法按时偿还贷款的可能。
                                BB+等级表示略高于BB级，BB-表示略低于BB级，(+)/(-)不影响企业所属信用等级的评价标准。
                            </span>
                            <span style="font-size: 16px;" v-if="creditInfo.finalclass==='B+' || creditInfo.finalclass==='B' || creditInfo.finalclass==='B-'">
                                企业的信用程度差，偿债能力较弱。企业的贷款出现风险的可能性比较大。
                                B+等级表示略高于B级，B-表示略低于B级，(+)/(-)不影响企业所属信用等级的评价标准。
                            </span>
                            <span style="font-size: 16px;" v-if="creditInfo.finalclass==='CCC+' || creditInfo.finalclass==='CCC' || creditInfo.finalclass==='CCC-'">
                               企业信用很差，几乎没有偿债能力。这类企业由于偿债能力弱，金融机构往往不太愿意给予贷款支持。
                                CCC+等级表示略高于CCC级，CCC-表示略低于CCC级，(+)/(-)不影响企业所属信用等级的评价标准。
                            </span>
                            <span style="font-size: 16px;" v-if="creditInfo.finalclass==='CC+' || creditInfo.finalclass==='CC' || creditInfo.finalclass==='CC-'">
                                企业信用极差，没有偿债能力。金融机构如果给予这类企业一定的贷款，那么这笔贷款大概率会出现逾期现象。
                                CC+等级表示略高于CC级，CC-表示略低于CC级，(+)/(-)不影响企业所属信用等级的评价标准。
                            </span>
                            <span style="font-size: 16px;" v-if="creditInfo.finalclass==='C+' || creditInfo.finalclass==='C' || creditInfo.finalclass==='C-'">
                                企业无信用。这类企业基本已经失去了存活的能力，大概率无法获得金融机构融资。
                                C+等级表示略高于C级，C-表示略低于C级，(+)/(-)不影响企业所属信用等级的评价标准。
                            </span>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </div>
        </div>
        <div class="content-center m-4" style="margin-right: 1.5rem" v-else>
            <div class="wi80">
                <el-card class="mt-3" shadow="never">

            <el-form :model="creditFrom" ref="credit" :rules="dataRule" :inline="false"
                     style="margin-left: 15px;margin-right: 15px;"
                     label-width="160px">
                <div class="title">
                    基本信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="companyName" label="企业名称：">
                            <el-input v-model="creditFrom.companyName" placeholder="企业名称">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="companyId" label="统一社会信用代码：">
                            <el-input v-model="creditFrom.companyId" placeholder="统一社会信用代码">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="calculateYear" label="年份：">
                            <el-input v-model="creditFrom.calculateYear" placeholder="年份">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    长短期偿债能力
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info1" label="利息保障倍数：">
                            <el-input v-model="creditFrom.info1" placeholder="利息保障倍数">
                            　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=（净利润+所得税费用+财务费用)／财务费用，利息保障倍数是衡量企业偿债能力的指标，利息保障倍数越大，说明企业支付利息费用的能力越强"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info2" label="速动比率：">
                            <el-input v-model="creditFrom.info2" placeholder="速动比率" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=(流动资产－存货)/流动负债*100%，衡量企业剔除存货后流动资产在短期债务到期以前，可以变为现金用于偿还负债的能力。 速动比率越高，企业的短期偿债能力越强"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info3" label="经营现金流动负债比：">
                            <el-input v-model="creditFrom.info3" placeholder="经营现金流动负债比" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=经营活动产生的现金流量净额／流动负债合计，从现金流量角度来反映企业当期偿付短期负债的能力，经营现金流动负债比率越高，企业的短期偿债能力越强"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info4" label="资产负债率：">
                            <el-input v-model="creditFrom.info4" placeholder="资产负债率" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=（负债总额/资产总额）*100%，衡量企业利用债权人提供资金进行经营活动的能力，反映企业财务杠杆水平，在一定范围内，资产负债率越高，企业的债务负担越重"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    盈利能力
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info5" label="净资产收益率：">
                            <el-input v-model="creditFrom.info5" placeholder="净资产收益率">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=（净利润/所有者权益×100%），是衡量企业股东权益的收益水平和企业运用自有资本的效率。净资产收益率越高，股东投资带来的收益越高，企业盈利能力越强"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info6" label="资产报酬率：">
                            <el-input v-model="creditFrom.info6" placeholder="资产报酬率" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=(净利润+利息费用+所得税)/平均资产总额×100%，表示企业全部资产获取收益的水平，全面反映了企业的获利能力和投入产出状况"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info7" label="营业利润率：">
                            <el-input v-model="creditFrom.info7" placeholder="营业利润率" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=营业利润/全部业务收入×100%，营业利润率越高，说明企业商品销售额提供的营业利润越多，企业的盈利能力越强；反之，此比率越低，说明企业盈利能力越弱"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    合同履约
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info8" label="和供应商的经济合同爽约/纠纷次数：">
                            <el-input v-model="creditFrom.info8" placeholder="和供应商的经济合同爽约/纠纷次数">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="违约次数越少，说明企业的合同履约风险越小"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info9" label="和分销商的经济合同爽约/纠纷次数：">
                            <el-input v-model="creditFrom.info9" placeholder="和分销商的经济合同爽约/纠纷次数" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="违约次数越少，说明企业的合同履约风险越小"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info10" label="信用借款比例：">
                            <el-input v-model="creditFrom.info10" placeholder="信用借款比例" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=信用借款额/借款总额×100%，信用借款部分要求金融机构无需企业做抵质押品即可获取对应资金，因此这一指标反映了金融机构在经过调查评估后对借贷企业的信任程度"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    福利与社保
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info11" label="为员工办理保障性待遇情况：">
                            <el-select v-model="creditFrom.info11" placeholder="请选择" style="width: 90%">
                                <el-option
                                        v-for="item in info11Options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                                　<span>
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="七种常见的保障性待遇办理情况：1-7依次对应 没有办理任何保障性待遇、五险一金、五险二金、六险一金、六险二金、七险一金、七险二金，待遇越高，说明该企业在福利发放、社保缴纳方面更有担当"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>

                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    质量承诺履约
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info12" label="产品问题反馈是否积极处理：">
                            <el-select v-model="creditFrom.info12" placeholder="请选择" style="width: 90%">
                                <el-option
                                        v-for="item in info12Options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            　<span>
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="采用7级李克特量表方式进行程度自评（1-7依次对应 非常不积极、不积极、比较不积极、一般、比较积极、积极、非常积极），考量企业产品/服务的质量承诺履约能力"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>

                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info13" label="售后是否令顾客满意：">
                            <el-select v-model="creditFrom.info13" placeholder="请选择" style="width: 90%">
                                <el-option
                                        v-for="item in info13Options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            　<span>
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="采用7级李克特量表方式进行程度自评（1-7依次对应 非常不满意、不满意、比较不满意、一般、比较满意、满意、非常满意），考量企业产品/服务的质量承诺履约能力"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>

                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    工资及支付
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info14" label="工资拖欠情况：">
                            <el-select v-model="creditFrom.info14" placeholder="请选择" style="width: 90%">
                                <el-option
                                        v-for="item in info14Options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            　<span>
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="采用7级李克特量表方式进行程度自评（1-7依次对应 从未支付、经常拖欠且常不足额支付、经常拖欠但足额支付、视公司运营情况拖欠、间歇性拖欠、偶尔拖欠 、从不拖欠），考量企业工资支付情况"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>

                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    营运能力
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info15" label="应收账款周转率：">
                            <el-input v-model="creditFrom.info15" placeholder="应收账款周转率">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=营业收入/应收账款期末净额×100%，应收账款周转率越高,表明企业收账速度快，平均收账期短，坏账损失少，企业的生产经营情况越稳定"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info16" label="存货周转率：">
                            <el-input v-model="creditFrom.info16" placeholder="存货周转率" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=营业成本／存货期末余额×100%，衡量和评价企业购入存货、投入生产、销售收回等各环节管理状况的综合性指标，存货周转率越高，表明企业存货资产变现能力越强，存货及占用在存货上的资金周转速度越快"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info17" label="总资产周转率：">
                            <el-input v-model="creditFrom.info17" placeholder="总资产周转率" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=营业收入净额/平均资产总额，体现企业经营期间全部资产从投入到产出的流转速度，反映了企业全部资产的管理质量和利用效率，次数越高，企业资产管理水平和运营效率越高"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    发展能力
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info18" label="营业收入增长率：">
                            <el-input v-model="creditFrom.info18" placeholder="营业收入增长率">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=(营业收入本年本期单季度金额—营业收入上一个单季度金额)/(营业收入上一个单季度金额)，该指标大于0，表示企业的营业收入有所增长，指标值越高，表明增长速度越快，企业市场前景越好；若该指标小于0，则说明存在产品或服务不适销对路、质次价高等方面问题，市场份额萎缩"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info19" label="资产保值增值率：">
                            <el-input v-model="creditFrom.info19" placeholder="资产保值增值率" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=(所有者权益合计)本期期末值/(所有者权益合计)本期期初值，反映了投资者投入企业资本的保全性和增长性，该指标越高，表明企业的资本保全状况越好，所有者权益增长越快，债权人的债务越有保障，企业发展后劲越强"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info20" label="营业利润增长率：">
                            <el-input v-model="creditFrom.info20" placeholder="营业利润增长率" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="=(营业利润本年本期单季度金额—营业利润上一个单季度金额)/(营业利润上一个单季度金额)，营业利润率越高，说明企业百元商品销售额提供的营业利润越多，企业的盈利能力越强；反之，此比率越低，说明企业盈利能力越弱"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    高级管理者素质
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info21" label="教育背景：">
                            <el-input v-model="creditFrom.info21" placeholder="教育背景">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="企业高级管理者中硕士及以上学历人数/高级管理者总数×100%，比率越高，表明高级管理者的素质越高，越有可能对企业进行科学管理"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info22" label="兼任公司比例：">
                            <el-input v-model="creditFrom.info22" placeholder="兼任公司比例" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="企业高级管理者兼任其他公司董事的高级管理者的人数/高级管理者总数×100%，高级管理者兼任董事的比例越高，公司的信用担保风险越小"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info23" label="兼任上市公司高管情况：">
                            <el-input v-model="creditFrom.info23" placeholder="兼任上市公司高管情况" style="width: 100%;">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="企业全体高级管理者累计兼任其他上市公司的高级管理者的数量，该数值越高，越有利于企业获取更优质的融资条件"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    管理制度
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info24" label="管理体系认证情况：">
                            <el-select v-model="creditFrom.info24" placeholder="请选择" style="width: 90%">
                                <el-option
                                        v-for="item in info24Options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            　<span>
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="现存有效的管理体系认证数量，0到6以上对应7个量级
综合认证结果查询列表 (cnca.cn)，这个网站可以直观查询各个企业的管理认证体系情况（爬取存在难度，每一步均存在图形验证）
ISO管理体系认证数量，0到6以上对应7个量级"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>

                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info25" label="安全管理情况：">
                            <el-select v-model="creditFrom.info25" placeholder="请选择" style="width: 90%">
                                <el-option
                                        v-for="item in info25Options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            　<span>
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         placement="top-start">
                                     <div slot="content">安全管理内容：
(1)	制定以安全生产责任制为核心的各项安全规章制度。
(2)	编制安全生产规划。
(3)	实行安全技术管理。
(4)	开展安全教育与培训。
(5)	组织安全检查。
(6)	开展事故调查、统计与分析。
(7)	安全奖惩。<br/>(8)	开展系统安全分析与安全评价。
(9)	组织安全质量标准化达标活动。
(10)	开展安全月和安全日活动等。
对于给出的10项安全管理内容，按企业现有安全管理制度符合情况数量进行考量，符合情况越多，说明企业的安全管理制度越完善</div>
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>

                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="info26" label="人力管理情况：">
                            <el-select v-model="creditFrom.info26" placeholder="请选择" style="width: 90%">
                                <el-option
                                        v-for="item in info26Options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            　<span>
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="人力管理内容：
(1)	职务分析与设计。
(2)	人力资源规划
(3)	员工招聘与选拔
(4)	绩效考评
(5)	薪酬管理
(6)	员工记录
(7)	培训与开发
(8)	职业生涯规划
对于给出的8项人力管理内容，按企业现有人力管理制度包含情况数量进行考量，符合情况越多，说明企业的人力管理制度越完善
"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>

                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="title">
                    创新能力
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="info27" label="企业专利、软著登记数量：">
                            <el-input v-model="creditFrom.info27" placeholder="净资产收益率">
                                　<span slot="suffix">
                                 <el-tooltip
                                         class="item"
                                         effect="dark"
                                         content="使用企业截止统计期末已获得专利数量对企业的技术水平及壁垒进行衡量，数量越多，说明企业的创新能力越强，其运营的可持续性越强"
                                         placement="top-start">
                                　　　　　<i class="el-icon-info">
                                　　　　　</i>
                                　</el-tooltip>
                            </span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
                    <div class="mt-3 uf uf-ac uf-pc">
<!--                        <el-button style="margin-right: 15px;" @click="clear">清空</el-button>-->
                        <el-button type="primary" @click="dataFormSubmit">提交</el-button>
                    </div>
                </el-card>
            </div>
        </div>
        <recommend-product ref="product"></recommend-product>
    </div>
</template>
<script>
    import {head} from 'lodash'
    import moment from 'moment'
    import RecommendProduct from '../../components/recommend-product'
    export default {
        name: 'general-quality-result',
        filters: {
            dateFilter (date) {
                return moment(date).format('yyyy-MM-DD')
            }
        },
        components: {
            RecommendProduct
        },
        data() {
            return {
                loading: false,
                old: false,
                firstInstance: {},
                secondInstance: {},
                creditFrom: {companyId: '',
                    companyName: ''},
                info11Options: [
                    {
                    value: '1',
                    label: '1分-没有办理任何保障性待遇'
                }, {
                    value: '2',
                    label: '2分-五险一金'
                }, {
                    value: '3',
                    label: '3分-五险二金'
                }, {
                    value: '4',
                    label: '4分-六险一金'
                }, {
                    value: '5',
                    label: '5分-六险二金'
                }, {
                    value: '6',
                    label: '6分-七险一金'
                }, {
                    value: '7',
                    label: '7分-七险二金'
                }],
                info12Options: [
                    {
                    value: '1',
                    label: '1分-非常不积极'
                }, {
                    value: '2',
                    label: '2分-不积极'
                }, {
                    value: '3',
                    label: '3分-比较不积极'
                }, {
                    value: '4',
                    label: '4分-一般'
                }, {
                    value: '5',
                    label: '5分-比较积极'
                }, {
                    value: '6',
                    label: '6分-积极'
                }, {
                    value: '7',
                    label: '7分-非常积极'
                }],
                info13Options: [
                    {
                    value: '1',
                    label: '1分-非常不满意'
                }, {
                    value: '2',
                    label: '2分-不满意'
                }, {
                    value: '3',
                    label: '3分-比较不满意'
                }, {
                    value: '4',
                    label: '4分-一般'
                }, {
                    value: '5',
                    label: '5分-比较满意'
                }, {
                    value: '6',
                    label: '6分-满意'
                }, {
                    value: '7',
                    label: '7分-非常满意'
                }],
                info14Options: [
                    {
                    value: '1',
                    label: '1分-从未支付'
                }, {
                    value: '2',
                    label: '2分-经常拖欠且常不足额支付'
                }, {
                    value: '3',
                    label: '3分-经常拖欠但足额支付'
                }, {
                    value: '4',
                    label: '4分-视公司运营情况拖欠'
                }, {
                    value: '5',
                    label: '5分-间歇性拖欠'
                }, {
                    value: '6',
                    label: '6分-偶尔拖欠'
                }, {
                    value: '7',
                    label: '7分-从不拖欠'
                }],
                info24Options: [
                    {
                    value: '1',
                    label: '1分-0个有效期内管理体系认证数量'
                }, {
                    value: '2',
                    label: '2分-1个有效期内管理体系认证数量'
                }, {
                    value: '3',
                    label: '3分-2个有效期内管理体系认证数量'
                }, {
                    value: '4',
                    label: '4分-3个有效期内管理体系认证数量'
                }, {
                    value: '5',
                    label: '5分-4个有效期内管理体系认证数量'
                }, {
                    value: '6',
                    label: '6分-5个有效期内管理体系认证数量'
                }, {
                    value: '7',
                    label: '7分-6个及以上有效期内管理体系认证数量'
                }],
                info25Options: [
                    {
                    value: '1',
                    label: '1分-符合1条'
                }, {
                    value: '2',
                    label: '2分-符合2条'
                }, {
                    value: '3',
                    label: '3分-符合3条'
                }, {
                    value: '4',
                    label: '4分-符合4条'
                }, {
                    value: '5',
                    label: '5分-符合5条'
                }, {
                    value: '6',
                    label: '6分-符合6条'
                }, {
                    value: '7',
                    label: '7分-符合7条'
                }, {
                    value: '8',
                    label: '8分-符合8条'
                }, {
                    value: '9',
                    label: '9分-符合9条'
                }, {
                    value: '10',
                    label: '10分-符合10条'
                }],
                info26Options: [
                    {
                    value: '1',
                    label: '1分-符合1条'
                }, {
                    value: '2',
                    label: '2分-符合2条'
                }, {
                    value: '3',
                    label: '3分-符合3条'
                }, {
                    value: '4',
                    label: '4分-符合4条'
                }, {
                    value: '5',
                    label: '5分-符合5条'
                }, {
                    value: '6',
                    label: '6分-符合6条'
                }, {
                    value: '7',
                    label: '7分-符合7条'
                }, {
                    value: '8',
                    label: '8分-符合8条'
                }],
                enterpriseInfo: {},
                creditInfo: {},
                class1: [],
                class2: [],
                labelStyle: {
                    width: '350px',
                    textAlign: 'center'
                },
                contentStyle: {
                    width: '200px',
                    textAlign: 'center'
                },
                dataRule: {
                    companyId: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ],
                    companyName: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ],
                    calculateYear: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ],
                    info1: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ],
                    info2: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info3: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info4: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info5: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info6: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info7: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info8: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info9: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info10: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info11: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info12: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info13: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info14: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info15: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info16: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info17: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info18: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info19: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info20: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info21: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info22: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info23: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info24: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info25: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info26: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ], info27: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ]
                }
            }
        },
        computed: {
            enterpriseList: {
                get() {
                    return this.$store.state.user.info
                },
                // set (val) { this.$store.commit('user/updateInfo', val) }
            },
            enterpriseId() {
                // lodash的_.head方法取第一个企业信息
                let defaultEnterpriseInfo = head(this.enterpriseList)
                return defaultEnterpriseInfo ? defaultEnterpriseInfo.id : ''
            }
        },
        watch: {
        },
        created() {
            this.initCredit()
            this.initBasicInfo()
        },
        mounted() {
            this.initRecommendProduct()
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            },
            // 基本信息
            initBasicInfo() {
                this.loading = true
                this.$http({
                    url: this.$http.adornUrl(this.$api.QCC.ENT_INFO.INFO),
                    method: 'GET',
                    params: this.$http.adornParams({
                        enterpriseId: this.enterpriseId
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.enterpriseInfo = data['result'] || {}
                        this.creditFrom.companyId = this.enterpriseInfo.creditCode
                        this.creditFrom.companyName = this.enterpriseInfo.name
                        this.loading = false
                    }

                    if (this.loadingCount) {
                        this.loadingCount -=1
                    }
                })
            },
            initRecommendProduct() {
                this.$nextTick(() => {
                    this.$refs.product.init(this.enterpriseId)
                })
            },
            // 表单提交
            dataFormSubmit () {
                this.creditFrom.enterpriseId = this.enterpriseId
                this.$refs['credit'].validate((valid) => {
                    if (valid) {
                        this.$confirm('确定提交?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.loading = true
                            this.$http({
                                url: this.$http.adornUrl(this.$api.TECH.SELF_CREDIT.SAVE),
                                method: 'post',
                                data: this.$http.adornData(this.creditFrom)
                            }).then(({data}) => {
                                if (data && data.code === 0) {
                                    this.$message({
                                        message: '操作成功',
                                        type: 'success',
                                        duration: 1500
                                    })
                                    this.initCredit()
                                    this.loading = false
                                } else {
                                    this.$message.error(data.msg)
                                    this.loading = false
                                }
                            })
                        })
                    }
                })
            },
            drawChart() {
                let posDom = document.getElementById('firstChart')
                this.firstInstance = this.echarts.init(posDom)
                let option = {
                    title: {
                        text: '企业综合评价'
                    },
                    legend: {
                        data: []
                    },
                    radar: {
                        // shape: 'circle',
                        indicator: [
                            { name: '财务风险', max: 10 },
                            { name: '履约风险', max: 10 },
                            { name: '运营风险', max: 10 }
                        ]
                    },
                    series: [
                        {
                            name: 'Budget vs spending',
                            type: 'radar',
                            data: [
                                {
                                    value: this.class1,
                                    name: 'Actual Spending'
                                }
                            ]
                        }
                    ]
                }
                this.firstInstance.setOption(option)
            },
            drawSecondChart() {
                let secondDom = document.getElementById('secondChart')
                this.secondInstance = this.echarts.init(secondDom)
                let option = {
                    title: {
                        text: '企业各项指标'
                    },
                    legend: {
                        data: []
                    },
                    radar: {
                        // shape: 'circle',
                        indicator: [
                            { name: '长短期偿债能力', max: 10 },
                            { name: '盈利能力', max: 10 },
                            { name: '合同履约', max: 10 },
                            { name: '融资信用', max: 10 },
                            { name: '福利与社保', max: 10 },
                            { name: '质量承诺履约', max: 10 },
                            { name: '工资及支付', max: 10 },
                            { name: '营运能力', max: 10 },
                            { name: '发展能力', max: 10 },
                            { name: '管理能力', max: 10 },
                            { name: '创新能力', max: 10 }
                        ]
                    },
                    series: [
                        {
                            name: 'Budget vs spending',
                            type: 'radar',
                            data: [
                                {
                                    value: this.class2,
                                    name: 'Allocated Budget'
                                }
                            ]
                        }
                    ]
                }
                this.secondInstance.setOption(option)
            },
            initCredit() {
                this.$http({
                    url: this.$http.adornUrl(this.$api.TECH.SELF_CREDIT.INFO),
                    method: 'POST',
                    params: this.$http.adornParams({
                        enterpriseId: this.enterpriseId
                    })
                }).then(({data}) => {
                    if (data && data.code === 0 && data.data) {
                        this.creditInfo = data.data
                        this.class1 = [this.creditInfo.class1info1, this.creditInfo.class1info2, this.creditInfo.class1info3]
                        this.class2 = [this.creditInfo.class2info1, this.creditInfo.class2info2, this.creditInfo.class2info3, this.creditInfo.class2info4, this.creditInfo.class2info5, this.creditInfo.class2info6, this.creditInfo.class2info7, this.creditInfo.class2info8, this.creditInfo.class2info9, this.creditInfo.class2info10, this.creditInfo.class2info11]
                        this.old = true
                        this.$nextTick(() => {
                            this.drawChart()
                            this.drawSecondChart()
                        })
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .quality-container {
        width: 100%;
        background-color: #FFFFFF;
        height: 50%;
        padding: 2rem 2% .4rem 2%;
    }

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-bottom: .3rem;
    }
    .content-center {
        background-color: #FFFFFF;
        margin-top: .5rem;
        /*width: 100%;*/
        min-height: 400px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .content-item {
        width: 100%;
        height: 100%;
        /*background-color: #409EFF;*/
    }
    .header-extra {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        min-width: 200px;
    }
    .custom-divider {
        height: 100%;
        width: 10px;
        background-color: #f1f2f3;
    }
    .item-label {
        width: 250px;
        background: red;
    }
</style>
